<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Install this app</v-card-title>

        <v-card-text>
          <p>Install this application on your home screen for a quick and easy access when you are on the go.</p>
          <p>
            Just tap &nbsp;
            <img :src="img" height="27px" width="19px" />
            &nbsp;&nbsp;then 'Add to Home Screen'
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="handleDismiss()">Dismiss</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'AddToHomeScreen',
  data: () => ({
    dialog: true
  }),
  computed: {
    img() {
      return `${process.env.BASE_URL}img/Navigation_Action.png`;
    }
  },
  methods: {
    handleDismiss() {
      localStorage.lastSeenPrompt = moment();
      this.$router.back();
    }
  }
};
</script>

<style></style>
